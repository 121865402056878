/**
 * Exhibit card display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if cards are present
 */

const cardToggle = (() => {
  // first time logic to hide / show cards
  function toggleInit() {
    if ($('.excard-toggle #upcoming').is(':checked')) {
      $('.excard-short.current, .excard-long.current').css({ display: 'none' });
      $('.excard-short.upcoming, .excard-long.upcoming').css({
        display: 'block',
      });
    } else {
      $('.excard-short.current, .excard-long.current').css({
        display: 'block',
      });
      $('.excard-short.upcoming, .excard-long.upcoming').css({
        display: 'none',
      });
    }
  }

  // Render carousel
  function toggleCards(e) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if ($('.excard-toggle #upcoming').is(':checked')) {
      $('.excard-short.current, .excard-long.current').fadeOut(300);
      $('.excard-short.upcoming, .excard-long.upcoming').fadeIn(300);

      urlParams.set('exhibitions', 'upcoming');
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        { exhibitions: urlParams.get('exhibitions') },
        'title',
        `?exhibitions=upcoming`,
      );
    } else {
      $('.excard-short.current, .excard-long.current').fadeIn(300);
      $('.excard-short.upcoming, .excard-long.upcoming').fadeOut(300);

      urlParams.set('exhibitions', 'current');
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        { exhibitions: urlParams.get('exhibitions') },
        'title',
        `?exhibitions=current`,
      );
    }
  }

  function getURLParamAndToggle() {
    // ?exhibitions=current
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('exhibitions') === 'upcoming') {
      $('.excard-toggle #upcoming').click();
    } else {
      $('.excard-toggle #current').click();
    }
  }

  const init = () => {
    if (!$('.excard-short, .excard-long').length) {
      return;
    }

    toggleInit();
    $('.excard-toggle input[name="showexhibits"]').on('click', toggleCards);
    getURLParamAndToggle();
  };

  return {
    init,
  };
})();

export default cardToggle;
