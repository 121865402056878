/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if instagram is present
 */
import lastWord from '../object/last-word';

const eventNewsRSS = (() => {
  const lastWord = (elem) => {
    const cta = $(elem);

    cta.each((index) => {
      $(cta[index]).addClass('link--arrow');
    });

    cta.html((index, element) => {
      // Remove trailing whitespaces
      const textWOWhite = element.trim();
      // separate the text by spaces
      const text = textWOWhite.split(' ');
      // drop the last word and store it in a variable
      const last = text.pop();
      // join the text back and if it has more than 1 word add the span tag
      // to the last word
      return `${text.join(' ')} ${
        text.length >= 0 ? `<span class="last-word">${' '}${last}<span class="arrow"></span></span>` : last
      }`;
    });
  };

  function getFeedEvent() {
    const $elem = $('section.events3up, .discover__item.events3up');
    const $componentCount = $elem.length;

    // For each component, output feed
    for (let i = 0; i < $componentCount; i++) {
      const $componentInQuestion = $elem.eq(i - 1);
      const $URL = $componentInQuestion.attr('data-url');

      // If data attribute exists, get data
      if ($URL !== undefined) {
        $.ajax({
          method: 'GET',
          url: $URL,
        }).done(data => {
          const count = 3;
          let html = '';

          // Given data, remove outdated events
          for (let i = 0; i < data.events.length - 1; i++) {
            const dateInQuestion = new Date(data.events[i].start_date);
            const todaysDate = new Date;

            if (dateInQuestion < todaysDate) {
              data.events.splice(i, 1);
            }
            else {
              break;
            }
          }
    
          // Grab the items needed based on count variable
          for (let i = 0; i < count; i++) {
            const item = data.events[i];
            const date = new Date(data.events[i].start_date);
            const months = ["January", "February", "March", "April", "May", "June",
              "July", "August", "September", "October", "November", "December"
            ];
            
            // Store items in varaible to be appended to the DOM later
            html += `
          <div class="column__col events3up__item wow fadeIn">
            <div class="events3up__date">
              <div class="events3up__month">${ months[date.getMonth()] }</div>
              <div class="events3up__day">${ item.start_date_details.day }</div>
            </div>
            <div class="events3up__content">
              <h3>
                <a href="${ item.url }">${ item.title }</a>
              </h3>
              <div class="events3up__data">
                <div class="events3up__location">${ item.venue }</div>
              </div>
            </div>
          </div>
          `;
          }
          
          // Finally, append all collected items to the DOM
          $componentInQuestion.find('.column--three').append(html);
        });

        setTimeout(() => {
          lastWord('.events3up h3 a');
        }, 2000);
      }
    }
  }

  function getFeedNews() {
    const $elem = $('section.blog3up');
    const $componentCount = $elem.length;

    // For each component, output feed
    for (let i = 0; i < $componentCount; i++) {
      const $componentInQuestion = $elem.eq(i - 1);
      const $URL = $componentInQuestion.attr('data-url');

      // If data attribute exists, get data
      if ($URL !== undefined) {
        $.ajax({
          method: 'GET',
          url: $URL,
        }).done(data => {
          const count = 3;
          let html = '';

          // Given data, remove outdated events
          for (let i = 0; i < data.length - 1; i++) {
            const dateInQuestion = new Date(data[i].date);
            const todaysDate = new Date;

            if (dateInQuestion < todaysDate) {
              // data.splice(i, 1);
            }
            else {
              break;
            }
          }
    
          // Grab the items needed based on count variable
          for (let i = 0; i < count; i++) {
            const item = data[i];
            const date = new Date(data[i].date);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
              "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
            ];

            // Gather author data, then render items
            const authorURL = item._links.author[0].href;

            $.ajax({
              method: 'GET',
              url: authorURL,
            }).done(authData => {
              const author = authData.name;
              const authorURL = authData.link;
              
              // Store items in varaible to be appended to the DOM later
              html = `
              <div class="column__col wow fadeIn">
                <div class="column__img">
                  <a href="${ item.link }"><img alt="${ item.title.rendered }" src="${ item.jetpack_featured_media_url }" /></a>
                </div>
                <div class="column__title">
                  <h3>
                    <a href="${ item.link }">${ item.title.rendered }</a>
                  </h3>
                </div>
                <div class="blog3up__event-info">
                  <div class="blog3up__date">${ months[date.getMonth()] } ${ date.getDate() }</div>
                  <div class="blog3up__author"><a href="${ authorURL }" class="link--arrow">${ author }</a></div>
                </div>
              </div>`;
          
              // Finally, append all collected items to the DOM
              $componentInQuestion.find('.column--three').append(html);
            });
          }
        });

        setTimeout(() => {
          lastWord('.blog3up h3 a');
        }, 2000);
      }
    }
  }

  const init = () => {
    if ($('.events3up').length) {
      getFeedEvent();
    }

    if ($('.blog3up').length) {
      getFeedNews();
    }

  };

  return {
    init,
  };
})();

export default eventNewsRSS;