import '@babel/polyfill';
import 'slick-carousel';
import 'object-fit-images';

import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import twitterLoaderMuseum from './object/twitterMuseum';
import facultyTable from './object/faculty-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import carouselExtemplate from './object/museum-carousel-extemplate';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import lastWord from './object/last-word';
import tableScroll from './object/table-scroll';
import header from './object/header';
import wowAnimate from './object/wow';
import gallery from './object/gallery';
import scrollTop from './object/scrollTop';
import impact from './object/impact';
import carousel3part from './object/carousel-3part';
import pollSlider from './object/slider-poll';
import poll from './object/poll';
import modalSlider from './object/slider-modal';
import researchVidFeat from './object/museum-research-vidfeat';
import imageDropGrid from './object/image-dropdown-grid';
import homepageParallax from './object/homepage-parallax';
import imageCaption from './object/image-caption';
import cardToggle from './object/museum-card-toggle';
import museumGalleryToggle from './object/museum-gallery-toggle';
import parallax from './object/parallax';
import componentParallax from './object/component-parallax';
import musHomeHero from './object_museum/museum-home-hero';
import eventNewsRSS from './object/events-news-rss';
import bootImgGrid from './object/boot-img-grid';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  accordion.init();
  inlineVideo.init();
  instagram.init();
  twitterLoader.init();
  twitterLoaderMuseum.init();
  facultyTable.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  carouselExtemplate.init();
  programFinder.init();
  catalog.init();
  lastWord.init();
  tableScroll.init();
  header.init();
  wowAnimate.init();
  gallery.init();
  scrollTop.init();
  impact.init();
  carousel3part.init();
  pollSlider.init();
  poll.init();
  modalSlider.init();
  researchVidFeat.init();
  imageDropGrid.init();
  homepageParallax.init();
  imageCaption.init();
  cardToggle.init();
  museumGalleryToggle.init();
  parallax.init();
  musHomeHero.init();
  eventNewsRSS.init();
  bootImgGrid.init();
  objectFitImages();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
      componentParallax.init();
    }, 100);
  });
});
