/* eslint-disable func-names */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */

require('datatables.net-dt');
require('datatables.net-buttons');

const facultyTable = (() => {

  const renameLabel = type => {
    if (type.toUpperCase() === 'NAME & TITLE') {
      return 'type';
    }

    if (type.toUpperCase() === 'OFFICE LOCATION') {
      return 'campusLocation';
    }

    return type.toLowerCase();
  };

  const renderTable = () => {
    const ajaxPath = 'https://www.fitnyc.edu/_resources/dmc/php/faculty.php?datasource=faculty&xpath=item&select=url,firstName,lastName,displayName,image,type,title,email,phone,degree,level,department,officeLocation,resumeCV&returntype=json&json_always_array=';
    // const ajaxPath = 'https://www.fitnyc.edu/_resources/dmc/php/faculty.php?datasource=faculty&xpath=item&select=url,firstName,lastName,displayName,image,type,title,email,phone,degree,level,department,officeLocation,resumeCV&returntype=json&json_always_array=';
    const $tableHolder = $('#faculty-table');

    function renderTable(sortedData) {
      const table = $tableHolder.DataTable({
        data: sortedData,
        processing: true,
        pagingType: 'simple_numbers',
        sPaginationType: 'ellipses',
        deferLoading: 57,
        ordering: false,

        language: {
          search: '<span class="search-title">Search Within Table</span>',
          searchPlaceholder: 'Type to filter table by keyword',
          infoFiltered: '',
          infoEmpty: '',
          info: `<span>_START_-_END_</span>
              <span>of</span>
              <span>_TOTAL_</span>`,
          sLengthMenu: `<div class="table-length-wrap">
            <select name="table-length" class="table-length form__control">
              <option value="10">Show <span>10</span> per page</option>
              <option value="20">Show <span>20</span> per page</option>
              <option value="30">Show <span>30</span> per page</option>
              <option value="40">Show <span>40</span> per page</option>
              <option value="50">Show <span>50</span> per page</option>
              <option value="-1">All</option>
            </select>
          </div>`,
        },
        columns: [
          {
            data: 'displayName',
          },
          {
            data: 'department',
          },
          {
            data: 'level',
          },
        ],
        columnDefs: [
          {
            targets: 0,
            data: 'displayName',
            render(data, type, row) {
              if (row.url) {
                return `<div class="row-section row-section--name">
                <span class="row-label">Name & Title</span>
                <div class="row-content">
                <a class="link-name" href="${row.url}">${row.displayName}</a>
                <span class="title">${row.title}</span>
                </div>
                </div>`;
              }
              return `<div class="row-section row-section--name">
                <span class="row-label">Name & Title</span>
                <div class="row-content">
                <span class="link-name" >${row.displayName}</span>
                <span class="title">${row.title}</span>
                </div>
                </div>`;
            },
          },
          {
            targets: 1,
            data: 'department',
            render(data, type, row) {
              if (data.length) {
                return `<div class="row-section row-section--department"><span class="row-label">Department</span><span class="department">${data}</span></div>`;
              }

              return `<div class="row-section row-section--department"></div>`;
            },
          },
          {
            targets: 2,
            data: 'level',
            render(data, type, row) {
              if (data.length) {
                return `<div class="row-section row-section--level"><span class="row-label">level</span><span class="level">${data}</span></div>`;
              }

              return `<div class="row-section row-section--level"></div>`;
            },
          },
          {
            targets: '_all',
            orderable: false,
          },
        ],
        dom:
          '<"heading-table"B<"heading-table-filter"<"mobile-filter-drop"<"toolbar">rf>>><"wrapper-table"<"table-holder"t>><"footer-table"p<"table-info"li>>',
        buttons: [
          {
            text: 'Faculty Directory Filters',
            className: 'filter-btn-drop',
            action: () => {
              const $filterHolder = $('.heading-table .heading-table-filter');
              const $filterToggle = $('.heading-table .filter-btn-drop');

              $filterToggle.toggleClass('expanded');
              $filterHolder
                .stop()
                .slideToggle(300)
                .attr(
                  'aria-hidden',
                  $filterHolder.attr('aria-hidden') === 'false'
                    ? 'true'
                    : 'false',
                );
            },
          },
        ],
        keys: true,
        responsive: true,
        initComplete() {
          this.api()
            .columns()
            .every(function (i) {
              const column = this;
              const info = this.page.info();
              const toolbar = $('.toolbar');
              const holder = document.createElement('div');
              const label = document.createElement('label');
              const searchHolder = $('#faculty-table_filter');
              const select = $(
                '<select class="form__control filter"><option value="">All</option></select>',
              );

              $(label).text(renameLabel(this.header().innerText));

              $(label)
                .addClass('form__label')
                .attr('for', `input-${i}`)
                .appendTo(holder);
              select.attr('id', `input-${i}`).appendTo(holder);
              $(holder)
                .addClass('filter-box')
                .appendTo(toolbar);
              searchHolder.appendTo(toolbar);

              select.on('change', function () {
                const val = $.fn.dataTable.util.escapeRegex($(this).val());
                const url = window.location.href.split('?')[0];
                const title = '';
                const param = `?dept=${$('#input-1').val()}`;
                window.history.replaceState(
                  {
                    dept: param,
                  },
                  title,
                  url + param,
                );

                if (column[0][0] === 2) {
                  column
                    .search(val ? `^level${val}$` : val, true, false, false)
                    .draw();
                } else {
                  // Regex below is dependant on the format of the json string
                  // Current format:Department{{ DEPT NAME }}; {{ DEPT NAME }}; {{ DEPT NAME }}
                  // The string "Department" is added for the mobile layout, and is accounted for as the start of the string
                  // 1st cond: Item is at beginning, with others after it (item ends in ';')
                  // 2nd cond: Item is the only dept, start and end of string
                  // 3rd cond: Item is last dept listed, multiple departments
                  // 4th cond: dept is in the middle of the string
                  const regex = `^Department${val};|^Department${val}$|\\s${val}$|\\s${val};`;
                  column
                    .search(regex, true, false, false)
                    .draw();
                }
              });

              column
                .data()
                .unique()
                .sort()
                // eslint-disable-next-line func-names
                .each(function (d) {
                  if (d.includes('; ')) {
                    const dataArr = d.split('; ');
                    // eslint-disable-next-line func-names
                    dataArr.forEach(function (val) {
                      const optionExists =
                        $(`option[value="${val}"]`).length > 0;
                      if (!optionExists) {
                        select.append(
                          `<option value="${val}">${val}</option>`,
                        );
                      }
                    });
                  } else {
                    const optionExists = $(`option[value="${d}"]`).length > 0;
                    if (!optionExists) {
                      select.append(`<option value="${d}">${d}</option>`);
                    }
                  }
                });
            });

          // Sort contents of department field alphabetically
          const select = $('#input-1');
          select.html(
            select.find('option').sort(function (a, b) {
              if ($(a).text() != 'All' && $(b).text() != 'All') {
                return $(a).text() > $(b).text() ? 1 : -1;
              }
            }),
          );
        },
      });

      const renderBtnClear = container => {
        const btnClear = document.createElement('button');
        const iconClear = document.createElement('i');
        const filterHolder = $(container);
        $(btnClear)
          .addClass('clear-table')
          .html('<span>Reset filter</span>');
        $(iconClear)
          .addClass('icon icon-refresh')
          .attr('aria-hidden', 'true');
        $(iconClear).appendTo(btnClear);
        $(btnClear).insertAfter(filterHolder);
      };

      const resetFilter = () => {
        $('.clear-table').on('click', function (e) {
          e.preventDefault();
          $('.filter').prop('selectedIndex', 0);
          table
            .search('')
            .columns()
            .search('')
            .draw();
        });
      };

      renderBtnClear('.dataTables_filter');
      resetFilter();
    }

    $.getJSON(ajaxPath, json => {
      const arr = json;

      // Sort array alphabetically before rendering table
      arr.data.sort(function (a, b) {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      renderTable(arr.data);
    }).done(() => {
      // Filter page by department / major
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const dept = urlParams.get('dept');

      if (dept != null || dept !== '') {
        $('#input-1').val(dept);
        $('#input-1').trigger('change');
      }
    });
  }

  const init = () => {
    if (!$('#faculty-table').length) {
      return;
    }

    renderTable();
  };
  return {
    init,
  };
})();

export default facultyTable;
