/**
 * Component Parallax display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Component Parallax is present
 */

const componentParallax = (() => {
  function setParallaxOffset(elem) {
    const relativeViewportOffset =
      elem.getBoundingClientRect().top - window.innerHeight * 0.5;
    const relativeParallax =
      elem.getBoundingClientRect().top - window.innerHeight * 0.5;
    const maxPixelOffset = 100;

    if (
      relativeViewportOffset < maxPixelOffset &&
      relativeViewportOffset > -1 * maxPixelOffset
    ) {
      elem.style.transform = `translateY(${relativeParallax * 0.5}px)`;
    } else if (relativeViewportOffset > maxPixelOffset) {
      elem.style.transform = `translateY(${maxPixelOffset * 0.5}px)`;
    } else if (relativeViewportOffset < maxPixelOffset) {
      elem.style.transform = `translateY(${-1 * maxPixelOffset * 0.5}px)`;
    }

    // If first item on page, prevent negative transform values; Effectively preventing overlap with page hero content
    if (
      (elem === elem.parentNode.firstElementChild ||
        elem === elem.parentNode.querySelectorAll('.parallax-component')[0]) &&
      relativeParallax * 0.5 < 0
    ) {
      elem.style.transform = 'translateY(0px)';
    }
  }

  function checkViewportWidthAndRunParallax($parallaxComponent) {
    if (window.innerWidth >= 700) {
      document.querySelectorAll($parallaxComponent).forEach(elem => {
        setParallaxOffset(elem);
      });
    } else {
      document.querySelectorAll($parallaxComponent).forEach(elem => {
        elem.style.transform = '0px';
      });
    }
  }

  const init = () => {
    const $parallaxComponent = '.parallax-component';

    if (!$($parallaxComponent).length) {
      return;
    }

    window.addEventListener('load', () => {
      checkViewportWidthAndRunParallax($parallaxComponent);
    });

    document.addEventListener('scroll', () => {
      checkViewportWidthAndRunParallax($parallaxComponent);
    });
  };

  return {
    init,
  };
})();

export default componentParallax;
