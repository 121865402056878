/**
 * Exhibit gallery caption toggle object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if gallery is present
 */

const galleryCaptionToggle = (() => {
  function toggleCaption() {

    if ($(this).attr('aria-expanded') === 'false') {
      $(this)
        .attr('aria-expanded', 'true')
        .find('.gallery__toggle-txt')
        .show();

      $(this)
        .next()
        .slideDown(300);
    } else {
      $(this)
        .attr('aria-expanded', 'false')
        .find('.gallery__toggle-txt')
        .hide();

      $(this)
        .next()
        .slideUp(300);
    }
  }

  function closeCaption() {
    $('.gallery__toggle').attr('aria-expanded', 'false');

    $('.gallery__toggle-txt').hide();

    $('.gallery__body').hide();
  }

  const init = () => {
    if (!$('.museum .gallery').length) {
      return;
    }

    $('.museum .gallery .gallery__toggle').on('click', toggleCaption);
    // $('.museum .gallery .gallery__nav').on('click', closeCaption);
  };

  return {
    init,
  };
})();

export default galleryCaptionToggle;
