/**
 * Gallery display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if gallery present
 */

const gallery = (() => {
  // Render carousel
  function renderCarousel() {
    $('.gallery').each(function (i, elem) {

      const $galWrapper = $(elem).find('.gallery__wrapper');
      const $galNav = $(elem).find('.gallery__nav');

      $galWrapper.slick({
        fade: true,
        slidesToShow: 1,
        rows: 0,
        asNavFor: $galNav,
        arrows: false,
      });

      $galNav.slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: $galWrapper,
        rows: 0,
        focusOnSelect: true,
      });

      $galWrapper.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        const $elemCurrSlide = $(event.currentTarget).find('.gallery__img').eq(currentSlide);
        const videoPlaying = $elemCurrSlide.find('.vid-inline__embed');

        if (videoPlaying.hasClass('js-show')) {
          if ($(videoPlaying).is('.vid-inline__embed--youtube')) {
            $(videoPlaying).children('.vid-inline__player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
          }
          else if (videoPlaying.is('.vid-inline__embed--vimeo')) {
            const iframe = $(videoPlaying).find('iframe');
            const src = $(iframe).attr('src');
            $(iframe).attr('src', `${src}?autoplay=1`);
          }
          else if (videoPlaying.hasClass('vid-inline__embed--html')) {
            const $video = videoPlaying.find('.video__vid')[0];

            if ($video.paused) {
              $video.play();
              $($video).parent().parent().next().addClass('js-pause');
            } else {
              $video.pause();
              $($video).parent().parent().next().removeClass('js-pause');
            }
          }
        }
      });
    });
  }

  const init = () => {
    if (!$('.gallery').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default gallery;
