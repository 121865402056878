const lastWord = (() => {
  const init = () => {
    const cta = $(
      '.column__title h3 a, a.genimglist__item .genimglist__title > h3, .program-list__program a, .article h2 a, .program-important-links__program a, .events3up h3 a, .image-links__title, .bootstrp .item__header > a, .bootstrp-card .item__header, .boot-img-grid .item__btn, .bootstrp-toolbox__title > a, .discover__magazine h3, .discover__newsroom h3, .discover__museum h3, .excard-short h2 a, .excard-long h2 a, .ontheroad__title a, .studentex__title a, .research-vidfeat h2 a, .collection-feat h2 a, .event__content h3 a, .home-news-meus__title a, .home-event-meus__link a, .home-intruders__one h3 a, .home-intruders h3 a',
    );

    cta.each((index) => {
      $(cta[index]).addClass('link--arrow');
    });

    cta.html((index, element) => {
      // Remove trailing whitespaces
      const textWOWhite = element.trim();
      // separate the text by spaces
      const text = textWOWhite.split(' ');
      // drop the last word and store it in a variable
      const last = text.pop();
      // join the text back and if it has more than 1 word add the span tag
      // to the last word
      return `${text.join(' ')} ${
        text.length >= 0 ? `<span class="last-word">${' '}${last}<span class="arrow"></span></span>` : last
      }`;
    });
  };

  return {
    init,
  };
})();

export default lastWord;
