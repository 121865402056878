/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

// eslint-disable-next-line global-require

const impact = (() => {
  // Render carousel
  function renderCarousel() {
    $('.impact-carousel__listing').slick({
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      adaptiveHeight: true,
      appendArrows: '.impact-carousel__controls',
      fade: true,
    });
  }

  function positionDots() {
    // let captionHeight = $(
    //   '.slick-current .impact__caption',
    // ).outerHeight();

    // let carouselWrapperHeight = $('.impact-carousel__listing').outerHeight();
    // let dotsPosition = `${carouselWrapperHeight - captionHeight}px`;

    // $('.slick-dots').css('margin-top', dotsPosition);

    // $('.impact ul').click(() => {
    //   captionHeight = $(
    //     '.slick-current .impact__caption',
    //   ).outerHeight();
    //   carouselWrapperHeight = $('.impact-carousel__listing').outerHeight();
    //   dotsPosition = `${carouselWrapperHeight - captionHeight}px`;

    //   $('.slick-dots').css('margin-top', dotsPosition);

    // });
  }

  const init = () => {
    if (!$('.impact').length) {
      return;
    }

    $(window).on('resize', function() {
      setTimeout(() => {
        positionDots();
      }, 100);
    });

    renderCarousel();

    setTimeout(() => {
      positionDots();
    }, 300);
  };

  return {
    init,
  };
})();

export default impact;