
const musHomeHero = (() => {
  const init = () => {
    if ($('.home-hero-mus').length) {
      $('.home-hero-mus__slides').slick({
        infinite: true,
        fade: true,
        speed: 300,
        slidesToShow: 1,
        dots: true,
        appendDots: '.home-hero-mus__controller',
        appendArrows: '.home-hero-mus__controller',
        adaptiveHeight: true,
      });
    }
  };

  return {
    init,
  };
})();

export default musHomeHero;
