const bootImgGrid = (() => {
  const init = () => {
    if (!$('.boot-img-grid').length) {
      return;
    }

    $('.boot-img-grid .item').each((i, elem) => {
      const wrapper = $(elem).find('.item__dropdown');
      const content = $(elem).find('.item__dropdown-inner');

      if (content.innerHeight() > wrapper.innerHeight()) {
        $(elem).addClass('js-overflow');
      }
    });
  };

  return {
    init,
  };
})();

export default bootImgGrid;
