/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carouselExtemplate = (() => {
  // Render carousel
  function renderCarousel() {
    $('.carousel-extemplate__wrapper').slick({
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
          },
        },
      ],
    });
  }

  const init = () => {
    if (!$('.carousel-extemplate').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carouselExtemplate;
