import simpleParallax from '../vendor/simpleParallax.min.js';
const homepageParallax = (() => {
  const init = () => {
    const image = document.getElementsByClassName('parallax-img');
    new simpleParallax(image, {
      delay: 0.3,
      orientation: 'down',
      scale: 1.5,
      overflow: true,
    });

    const imageUp = document.getElementsByClassName('parallax-img-up');
    new simpleParallax(imageUp, {
      delay: 0.3,
      orientation: 'up',
      scale: 1.5,
      overflow: true,
    });

    const imageOverflow = document.getElementsByClassName('parallax-img-overflow');
    new simpleParallax(imageOverflow, {
      delay: 0.3,
      orientation: 'down',
      scale: 1.35,
      overflow: false,
    });
  };

  return {
    init,
  };
})();

export default homepageParallax;
