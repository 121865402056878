/**
 * Slider modal display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if slider modal present
 */

const modalSlider = (() => {
  // Render carousel
  function renderCarousel() {
    const $modalOpener = $('.modal-opener');
    const $navClose = $('.slider-modal .close');
    const $videoFrames = document.querySelectorAll('.slider-modal iframe');
    const $navBlock = $('.hero-home');
    const $hero = $('.hero-home');
    const $body = $('body');
    const NAV_EXPANDED_CLASS = 'modal-open';

    $modalOpener.click((e) => {
      const $slideNumber = $($modalOpener).index(e.target);
      $('.home-modal .slider-modal').slick('slickGoTo', $slideNumber);
      const $btnNext = $('.slider-modal .slick-next');
      const $moreBtn = $('.home-modal .cta--button');
      $modalOpener.attr('aria-expanded', 'true');
      $navBlock.addClass('modal-open').attr('aria-hidden', 'false');
      $body.addClass('open-modal');
      const $moreBtnCurrent = $('.home-modal .slick-current .cta--button');
      setTimeout(() => {
        $moreBtnCurrent.focus();
      }, 350);
      // $moreBtn.get(0).focus();
      $btnNext.on('keydown', e => {
        const $moreBtnCurrent = $('.home-modal .slick-current .cta--button');
        if (e.keyCode === 9 && e.shiftKey === false) {
          e.preventDefault();
          $moreBtnCurrent.focus();
        }
      });
      $moreBtn.on('keydown', e => {
        if (
          e.keyCode === 9 &&
          e.shiftKey === true &&
          $hero.hasClass(NAV_EXPANDED_CLASS)
        ) {
          e.preventDefault();
          $btnNext.focus();
        }
      });
    });

    $navClose.click(() => {
      $modalOpener.attr('aria-expanded', 'false');
      $navBlock.removeClass('modal-open').attr('aria-hidden', 'true');
      $body.removeClass('open-modal');
      $('.slider-poll .slick-current .modal-opener').focus();
      for (let i = 0; i < $videoFrames.length; i++) {
        $videoFrames[i].contentWindow.postMessage(
          '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
          '*',
        );
        const src = $($videoFrames[i]).attr('src');
        if (src.indexOf('autoplay') !== -1) {
          $($videoFrames[i]).attr('src', src.replace('autoplay=1', ''));
        }
      }
    });

    $('.slider-modal').on('init', (event, slick) => {
      $(slick.$list)
        .closest('.slider-modal')
        .append('<div class="slides-number"></div>');
      const $slideStatus = $(slick.$list)
        .closest('.slider-modal')
        .find('.slides-number');
      $slideStatus.text(`${slick.currentSlide + 1} of ${slick.$slides.length}`);
    });

    $('.slider-modal').on('afterChange', (event, slick, currentSlide) => {
      const $slideStatus = $(slick.$list)
        .closest('.slider-modal')
        .find('.slides-number');
      
      $('.slider-poll').slick('slickGoTo', currentSlide);
      $slideStatus.text(`${currentSlide + 1} of ${slick.$slides.length}`);
    });

    $('.slider-modal').slick({
      infinite: true,
      fade: true,
      slidesToShow: 1,
      nextArrow:
        '<button class="slick-next slick-arrow" aria-label="Next" type="button">next topic</button>',
    });

    $('.slider-modal').on('afterChange', () => {
      const $moreBtn = $('.slider-modal .vid-inline__cover');
      const $moreBtnCurrent = $(
        '.slider-modal .slick-current .vid-inline__cover',
      );
      $moreBtn.attr('tabindex', -1);
      $moreBtnCurrent.attr('tabindex', 0);
    });
  }

  const init = () => {
    if (!$('.hero-home').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default modalSlider;
