/**
 * Carousel 3 part display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel 3 part present
 */

const carousel3part = (() => {
  // Render carousel
  function setBtnPosition(slick) {
    const currentSlideIndex = slick.currentSlide;
    const $currentSlide = $(slick.$slides[currentSlideIndex + 1]);
    const imgHeight = $currentSlide.find('img').height();
    const $arrows = $('.carousel-3part .slick-arrow');

    if ($(window).width() < 700) {
      $arrows.css('top', imgHeight);
    } else {
      $arrows.css('top', '');
    }
  }

  function renderCarousel() {
    $('.carousel-3part__wrapper').on('init', (event, slick) => {
      let timeOut = null;

      setBtnPosition(slick);

      $(window).resize(() => {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          setBtnPosition(slick);
        }, 100);
      });
    });

    jQuery('.carousel-3part__wrapper').each(function() {
      const holder = jQuery(this);
      const slider = holder.find('.carousel-3part__holder');
      const thumbs = holder.find('.thumbnail');

      slider.on('init', (event, slick) => {
        setActiveThumb(0);
      });
      slider.on('afterChange', (event, slick, currentSlide) => {
        setActiveThumb(currentSlide);
      });
      slider.slick({
        fade: true,
        slidesToShow: 1,
        rows: 0,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 699,
            settings: {
              arrows: false,
            },
          },
        ],
      });

      thumbs.on('click', e => {
        const currThumb = jQuery(e.target);
        const nextSlideIndex = currThumb.index();

        slider.slick('slickGoTo', nextSlideIndex);
      });

      function setActiveThumb(currSlide) {
        thumbs
          .eq(currSlide)
          .addClass('active')
          .siblings('.' + 'active')
          .removeClass('active');
      }
    });

    $('.carousel-3part__wrapper').on(
      'afterChange',
      (event, slick, currentSlide) => {
        setBtnPosition(slick);
      },
    );
  }

  const init = () => {
    if (!$('.carousel-3part').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel3part;
