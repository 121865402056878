/**
 * Slider poll display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if slider poll present
 */

const pollSlider = (() => {
  // Render carousel
  function renderCarousel() {
    $('.slider-poll').on('init', (event, slick) => {
      $(slick.$list)
        .closest('.slider-poll')
        .find('.slick-next')
        .append('<span class="slides-number"></span>');
      const $slideStatus = $(slick.$list)
        .closest('.slider-poll')
        .find('.slides-number');
      $slideStatus.text(`${slick.currentSlide + 1} of ${slick.$slides.length}`);
    });

    $('.slider-poll').on('afterChange', (event, slick, currentSlide) => {
      const $slideStatus = $(slick.$list)
        .closest('.slider-poll')
        .find('.slides-number');
      $slideStatus.text(`${currentSlide + 1} of ${slick.$slides.length}`);
    });

    $('.slider-poll').slick({
      infinite: true,
      fade: true,
      slidesToShow: 1,
      nextArrow:
        '<button class="slick-next slick-arrow" type="button"><span class="arrow-text">next topic</span></button>',
    });
  }

  const init = () => {
    if (!$('.hero-home').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default pollSlider;
