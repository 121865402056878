/**
 * Inline video play functionality using YouTube API
 * @param {*} $
 * @returns {init} Determine and run if inline video exists
 */

/* global YT */

const tag = document.createElement('script');
const body = document.getElementsByTagName('body')[0];

tag.src = 'https://www.youtube.com/iframe_api';
body.appendChild(tag);

const inlineVideo = (() => {
  function vidInline(e) {
    e.preventDefault();

    // Attach YouTube API
    const videoElem = $(this).parents('.vid-inline__container');
    const videoEmbedWrapper = this.nextElementSibling;
    const videoPlayer = this.nextElementSibling.querySelectorAll(
      '.vid-inline__player',
    )[0];

    let player;

    function vidToggleHTML(e) {
      const $video = e.currentTarget;
  
      if ($video.paused) {
        $video.play();
        $($video).parent().parent().next().addClass('js-pause');
      } else {
        $video.pause();
        $($video).parent().parent().next().removeClass('js-pause');
      }
    }

    // YouTube video player functions
    function onPlayerReady() {
      if (window.innerWidth >= 700) {
        player.playVideo();
      } else {
        // playVideo() will not always work on mobile devices per the YouTube IFrame API documentation, so on SM screens we will simply show the iframe when a user clicks on the video cover
        videoElem.find('.vid-inline__cover').addClass('js-hide');
        videoElem.find('.vid-inline__embed').addClass('js-show');
        videoElem.find('iframe').removeAttr('tabindex');
      }
    }

    function onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.UNSTARTED) {
        videoElem.find('.vid-inline__cover').addClass('js-loading');
      }

      if (event.data === YT.PlayerState.PLAYING) {
        videoElem.find('.vid-inline__cover').addClass('js-hide');
        videoElem.find('.vid-inline__embed').addClass('js-show');
        videoElem.find('iframe').removeAttr('tabindex');
      }
    }

    function onYouTubeIframeAPIReady() {
      setTimeout(() => {
        player = new YT.Player(videoPlayer.id, {
          height: '390',
          width: '640',
          videoId: videoPlayer.dataset.videoId,
          playerVars: {
            playsinline: 1,
          },
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
      }, 100);
    }

    // Vimeo video player functions
    function playInlineVimeo() {
      const iframe = videoElem.find('iframe');
      const src = $(iframe).attr('src');

      videoElem.find('.vid-inline__cover').addClass('js-hide');
      videoElem.find('.vid-inline__embed').addClass('js-show');
      videoElem.find('iframe').removeAttr('tabindex');

      $(iframe).attr('src', `${src}?autoplay=1`);
    }

    // HTML5 video player functions
    function playInlineHTML() {
      const $video = $(videoEmbedWrapper).find('.video__vid')[0];

      videoElem.find('.vid-inline__cover').addClass('js-hide');
      videoElem.find('.vid-inline__embed').addClass('js-show');

      $video.play();
      $($video).parent().parent().next().addClass('js-pause');

      $(videoEmbedWrapper).find('.video__vid').on('click keypress', vidToggleHTML);
    }

    // Determine video format type and trigger functions accordingly
    if ($(videoEmbedWrapper).hasClass('vid-inline__embed--youtube')) {
      onYouTubeIframeAPIReady();
    }
    else if ($(videoEmbedWrapper).hasClass('vid-inline__embed--vimeo')) {
      playInlineVimeo();
    }
    else if ($(videoEmbedWrapper).hasClass('vid-inline__embed--html')) {
      playInlineHTML();
    }
  }

  const handleTouchEvent = (e) => {
    e.preventDefault();
    e.target.click();
  };

  const init = () => {
    if (!$('.vid-inline').length) {
      return;
    }

    $('.vid-inline__cover').on('click keypress', vidInline);
    $('.vid-inline__cover').on('touchstart touchend', handleTouchEvent);
  };

  return {
    init,
  };
})();

export default inlineVideo;
