/**
 * 
 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */

const imageCaption = (() => {    
  function toggleDropdown(elem) {
    elem.preventDefault();
    elem.stopPropagation();

    if ($(elem.target).attr('aria-expanded') === 'false') {
      $(elem.target).attr('aria-expanded', 'true');
      $(elem.target).next().attr('aria-hidden', 'false');
    } else {
      $(elem.target).attr('aria-expanded', 'false');
      $(elem.target).next().attr('aria-hidden', 'true');
    }
  }

  const init = () => {
    if (!$('.img-cap').length) {
      return;
    }

    $('.img-cap__btn').on('click', toggleDropdown);
  };

  return {
    init,
  };
})();

export default imageCaption;

