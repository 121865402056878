const header = (() => {
  let $headerPrevScrollPos = 0;
  let $scrollPos = 0;
  let searchRecentlyOpened = false;

  const init = () => {
    const $body = document.querySelector('body');
    const $header = document.querySelector('header');
    const $mainNavButton = $header.querySelector('.menu__button');
    const $searchButton = $header.querySelector('.search__button');
    const $innerButtons = $header.querySelectorAll('.homepage-gfa');
    const $submenuOpener = $('header').find('li > .btn');
    const $mainNavTitleBtn = $('.main-nav-back');
    const $searchOpener = $('.header__inner .search__button');

    function alertToggle($target) {
      if ($('#oualerts-active-alert-display').length) {
        if ($body.classList.contains('js-fixed')) {
          if (
            $($target)[0].classList.contains('.menu__button') &&
            $body.classList.contains('js-open-main')
          ) {
            $('#oualerts-active-alert-display').slideToggle(300);
          }

          if (
            $($target)[0].classList.contains('.search__button') &&
            $body.classList.contains('js-open-search')
          ) {
            $('#oualerts-active-alert-display').slideToggle(300);
          }
        } else {
          $('#oualerts-active-alert-display').slideToggle(300);
        }
        return 300;
      }
    }

    function scrollDir() {
      const st = $(window).scrollTop();

      if (!searchRecentlyOpened) {
        if (!$('body').hasClass('js-nav-delay')) {
          if (st > $header.clientHeight) {
            $('.header').addClass('js-down');
          } else {
            $('.header').removeClass('js-down');
          }

          $headerPrevScrollPos = st;
        }

        if (st > $header.clientHeight) {
          $('.header').addClass('js-fixed');
        } else {
          $('.header').removeClass('js-fixed');
        }

        if (
          $body.classList.contains('js-open-search') &&
          !$body.classList.contains('js-open-main')
        ) {
          toggleDropdown($searchButton);
          $body.classList.remove('js-open-search');
          $('.search-form').slideUp(300);
          $('html').removeClass('scroll-lock');
        }
      }
    }

    $(window).on('scroll resize', scrollDir);

    // set min height to prevent inner navigation jumping
    function navInnerHeight() {
      $('.menu-dropdown__lvl-1').removeAttr('style');

      setTimeout(() => {
        const $height = $('.menu-dropdown__nav').height();

        const $open = $('.menu-dropdown__lvl-2[aria-hidden="true"]');

        if ($open.length) {
          $('.menu-dropdown__lvl-1').css('min-height', $height);
          $height;
        }
      }, 0);

      // inline Scrollbar detected, add class to header to position main navigation dividing lines appropriately
      $('header').removeClass('js-scrollOffset');
      $('.menu-dropdown__nav').css({
        width: '100%',
      });

      $.fn.hasScrollBar = function () {
        return this.get(0).scrollHeight > this.height();
      };

      if (
        $('.menu-dropdown__nav').hasScrollBar() &&
        $(window).width() >= 1024
      ) {
        $('header').addClass('js-scrollOffset');
      }
      $('.menu-dropdown__nav').removeAttr('style');
    }

    $(window).on('load resize', navInnerHeight);

    // $body.addEventListener('click', e => {
    //   if (!$(e.target).parents('header').length) {

    //     if ($($body).hasClass('js-open-main')) {
    //       e.stopPropagation();
    //       e.preventDefault();
    //       $mainNavButton.click();
    //     }

    //     if ($($body).hasClass('js-open-search')) {
    //       e.stopPropagation();
    //       e.preventDefault();
    //       $searchButton.click();
    //     }
    //   }
    // });

    // ## Toggle Dropdowns
    function toggleDropdown(elem) {
      if (elem.getAttribute('aria-expanded') === 'false') {
        elem.setAttribute('aria-expanded', 'true');
        elem.nextElementSibling.setAttribute('aria-hidden', 'false');
      } else {
        elem.setAttribute('aria-expanded', 'false');
        elem.nextElementSibling.setAttribute('aria-hidden', 'true');
      }
    }

    $header.querySelector('.header__inner').addEventListener('click', e => {
      e.stopPropagation();
    });

    // ## MAIN NAVIGATION

    // toggle
    $mainNavButton.addEventListener('click', e => {
      e.stopPropagation();
      let delay = 0;

      delay = alertToggle(e.target);

      setTimeout(() => {
        if ($body.classList.contains('js-open-main')) {
          $body.classList.remove('js-open-main');
          $body.classList.remove('scroll-lock');
          $body.classList.add('js-nav-delay');
          $('.wrapper').removeAttr('style');
          $(window).scrollTop($scrollPos);
          $('html').removeClass('scroll-lock');
        } else {
          $body.classList.add('js-open-main');
          $scrollPos = $(window).scrollTop();

          $body.classList.add('scroll-lock');
          $('.wrapper').css('top', -$scrollPos);
          $('html').addClass('scroll-lock');
        }

        toggleDropdown(e.target);
      }, delay);

      setTimeout(() => {
        $body.classList.remove('js-nav-delay');
        scrollDir();
      }, 200);
    });

    // tab loops
    if ($('.ancillary__ctas li:last-child a').length) {
      $header
        .querySelector('.ancillary__ctas li:last-child a')
        .addEventListener('keydown', e => {
          if (e.keyCode === 9 && !e.shiftKey) {
            e.preventDefault();
            $searchOpener.focus();
          }
        });
    }

    if ($('.menu-dropdown__ancillary .mfit-logo').length) {
      $header
        .querySelector('.menu-dropdown__ancillary .mfit-logo a')
        .addEventListener('keydown', e => {
          if (e.keyCode === 9 && !e.shiftKey) {
            e.preventDefault();
            $searchOpener.focus();
          }
        });
    }

    $searchOpener.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey) {
        if ($(e.target).attr('aria-expanded') === 'true') {
          e.preventDefault();
          $('.search-form > button').focus();
        }
      }
    });

    $('.search-form > button').on('keydown', e => {
      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        $searchOpener.focus();
      }
    });

    $searchOpener.on('keydown', e => {
      if ($('.menu__button').attr('aria-expanded') === 'true') {
        if (e.keyCode === 9 && e.shiftKey) {
          e.preventDefault();

          if ($('body').hasClass('museum')) {
            $('.menu-dropdown__ancillary .mfit-logo a').focus();
          } else {
            $('.ancillary__ctas li:last-child a').focus();
          }
        }
      }
    });

    // ## SEARCH

    // toggle
    $searchButton.addEventListener('click', e => {
      e.stopPropagation();
      let delay = 0;

      delay = alertToggle(e.target);

      setTimeout(() => {
        toggleDropdown(e.target);

        if ($body.classList.contains('js-open-search')) {
          $body.classList.remove('js-open-search');
          $('.search-form').slideUp(300);
          $('html').removeClass('scroll-lock');

          scrollDir();
        } else {
          searchRecentlyOpened = true;
          $body.classList.add('js-open-search');
          $header.classList.remove('js-down');
          $('.search-form').slideDown(300);
          $('#nav-search').focus();

          // used to reset global search opened event; used in preventing scroll actions
          // when search button is clicked on android devices due to virtual keyboard enabling,
          // forcing a scroll event to trigger
          setTimeout(() => {
            searchRecentlyOpened = false;
          }, 500);
        }
      }, delay);
    });

    // tab loops
    // document
    //   .querySelector('.search-form__button[type="submit"]')
    //   .addEventListener('keydown', e => {
    //     if (e.keyCode === 9 && !e.shiftKey) {
    //       e.preventDefault();
    //       $searchButton.focus();
    //     }
    //   });

    // $searchButton.addEventListener('keydown', e => {
    //   if (
    //     e.keyCode === 9 &&
    //     e.shiftKey &&
    //     e.target.getAttribute('aria-expanded') === 'true'
    //   ) {
    //     e.preventDefault();
    //     document.querySelector('.search__button[type="submit"]').focus();
    //   }
    // });

    // ## INNER NAVIGATION

    // Top level navigation items
    $submenuOpener.click(e => {
      const $this = $(e.currentTarget);

      if (!$this.hasClass('active-submenu')) {
        $('.menu-dropdown__lvl-1 > li').removeClass('selected');

        $this
          .addClass('active-submenu')
          .closest('ul')
          .siblings('ul')
          .animate({
              opacity: 0,
            },
            300,
          )
          .slideUp(300);

        $this.parent('ul > li').addClass('selected');

        $this
          .parent('ul > li')
          .siblings('ul > li')
          .animate({
              opacity: 0,
            },
            300,
          )
          .slideUp(300);

        $mainNavTitleBtn.addClass('menu-lvl-down');

        setTimeout(() => {
          $this
            .attr('aria-expanded', 'true')
            .siblings('ul')
            .slideDown(300 / 2)
            .animate({
                opacity: 1,
              },
              300,
            )
            .attr('aria-hidden', 'false');
        }, 600);
      } else {
        $this.parent('ul > li').removeClass('selected');

        $this
          .attr('aria-expanded', 'false')
          .siblings('ul')
          .animate({
              opacity: 0,
            },
            300,
          )
          .slideUp(300)
          .attr('aria-hidden', 'true');

        setTimeout(() => {
          $this.removeClass('active-submenu');

          if ($this.parent('ul').length) {
            $('ul')
              .slideDown(300)
              .animate({
                  opacity: 1,
                },
                300,
              );
            $mainNavTitleBtn.removeClass('menu-lvl-down');
          }

          $this
            .parent('ul > li')
            .siblings('ul > li')
            .slideDown(300)
            .animate({
                opacity: 1,
              },
              300,
            );
        }, 300);
      }

      const $firstLvlButtons = $(
        '.menu-dropdown__lvl-1 > li > .btn[aria-expanded="true"]',
      );
      if (!$firstLvlButtons.length) {
        $mainNavTitleBtn.removeClass('menu-lvl-down');
      }
    });

    $mainNavTitleBtn.click(e => {
      e.preventDefault();

      $mainNavTitleBtn.removeClass('menu-lvl-down');

      const $firstLvlButtons = $(
        '.menu-dropdown__lvl-1 > li > .btn[aria-expanded="true"]',
      );

      if ($firstLvlButtons.length) {
        $firstLvlButtons[0].click();
      }

      const $secondLvlButtons = $(
        '.menu-dropdown__lvl-2 > li > .btn[aria-expanded="true"]',
      );

      if ($secondLvlButtons.length) {
        $secondLvlButtons[0].click();
      }
    });

    // toggle
    Array.prototype.slice.call($innerButtons, 0).forEach(item => {
      item.addEventListener('click', e => {
        if (e.target.getAttribute('aria-expanded') === 'false') {
          Array.prototype.slice
            .call(
              $header.querySelectorAll(
                '.inner-nav__button[aria-expanded="true"]',
              ),
              0,
            )
            // eslint-disable-next-line max-nested-callbacks
            .forEach(i => {
              toggleDropdown(i);
              $(i.nextElementSibling).slideToggle();
            });
        }

        toggleDropdown(e.target);
        $(e.target.nextElementSibling).slideToggle();
      });
    });
  };

  /* 
  target the active page in the main navigation and trigger 
  the necessary click events to display it accordingly
  */
  setTimeout(() => {
    const $activeLink = $('.menu-dropdown__nav')
      .find('a.active-link')
      .eq(0);

    if ($activeLink.length) {
      if ($activeLink.closest('.menu-dropdown__lvl-2').length) {
        $activeLink
          .closest('.menu-dropdown__lvl-2')
          .eq(0)
          .prev()
          .click();
      }

      if ($activeLink.parents('.has-children').length) {
        const levels = $activeLink.parents('.has-children');
        levels.children('.btn').click();
      }

      if ($activeLink.siblings('.menu-dropdown__lvl-2').length) {
        $activeLink.next().click();
      }
    }
  }, 500);

  return {
    init,
  };
})();

export default header;