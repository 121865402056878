/**
 * Poll display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if poll present
 */

const poll = (() => {
  function init() {
    const readOnlyClass = 'locked';
    const checkedClass = 'checked';

    jQuery('.poll').each(function() {
      const holder = jQuery(this);
      const answerControls = holder.find('.button');
      const answerBars = holder.find('.line');
      let votes = [];
      // console.log(answerControls.attr('aria-pressed', 'false'));

      refreshProgressBar();
      answerControls.on('click', e => {
        const target = jQuery(e.target).closest('.button');
        const voteLine = holder.find('.line').eq(target.closest('li').index());
        let voteValue = voteLine.data('votes');
        voteLine.addClass(checkedClass).data('votes', ++voteValue);

        votes = [];
        refreshProgressBar();

        // Set locked state for the container
        holder.addClass(readOnlyClass);
        answerControls.addClass('disabled').attr('aria-pressed', 'false');
        target.addClass('selected').attr('aria-pressed', 'true');

        // Send quiz result to the server
        // jQuery.ajax({
        //  type: 'POST',
        //  url: '/response.php',
        //  data: {name: 'Line 1', value: voteValue},
        //  cache: false
        // });
      });

      function refreshProgressBar() {
        answerBars.each(function() {
          const bar = jQuery(this);
          const barValue = bar.data('votes');
          votes.push(barValue);
        });

        // Progress bar calculations
        const votesAmount = votes.reduce((acc, val) => acc + val);
        votes = votes.map(vote => ((vote / votesAmount) * 100).toFixed(3));
        drawProgressBar();
      }

      function drawProgressBar() {
        answerBars.each(function(index) {
          const bar = jQuery(this);
          $(bar).removeAttr('style');
          const progressAmount = bar.find('span:first-of-type');
          if ($(bar).hasClass('checked')) {
            bar.css({
              'min-width': `${votes[index]}%`,
            });
          }
          else {
            bar.css({
              'max-width': `${votes[index]}%`,
            });
          }
          progressAmount.html(`${(+votes[index]).toFixed()}%`);
        });
      }
    });
  }

  return {
    init,
  };
})();

export default poll;
