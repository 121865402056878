/**
 * imageDropGrid display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if imageDropGrid present
 */
const $ = require('jquery');

const imageDropGrid = (() => {
  const init = () => {
    if (!$('.boot-img-grid').length) {
      return;
    }

    $('.boot-img-grid .item__toggle').on('click', (e) => {
      if ($(e.target).attr('aria-expanded') === 'false') {
        $(e.target).attr('aria-expanded', 'true');
        $(e.target).next().attr('aria-hidden', 'false');
      } else {
        $(e.target).attr('aria-expanded', 'false');
        $(e.target).next().attr('aria-hidden', 'true');
      }
    });
  };

  return {
    init,
  };
})();

export default imageDropGrid;
