/**
 * Opens hidden content in academics learn section
 * @param {*} $
 * @returns {init} Determine and run learn opener present
 */

const parallax = (() => {
  function init() {
    const win = $(window);
    const parRate = 1.2;

    $('.hero-home__img').each(function() {
      const holder = $(this);
      const image = holder.find('.background-image');

      function recalculateImageSize() {
        image.css({
          height: holder.innerHeight() * parRate,
        });
      }
      recalculateImageSize();

      function recalculateImagePosition() {
        let position = win.scrollTop() / parRate;

        image.css({
          transform: `translateY(${position}px)`,
        });
      }

      win
        .on('resize orientationchange', function() {
          recalculateImageSize();
        })
        .on('scroll resize orientationchange', function() {
          recalculateImagePosition();
        });
    });
  }

  return {
    init,
  };
})();

export default parallax;
