/**
 * Work video feature play / pause functionality
 * @param {*} $
 * @returns {init} Determine and run if work video feature video exists
 */

const researchVidFeat = (() => {
  function vidInline(e) {
    e.preventDefault();

    const $video = document.getElementsByClassName('video__vid')[0];

    if ($video.paused) {
      $video.play();
      $(e.target).addClass('js-pause');
    }
    else {
      $video.pause();
      $(e.target).removeClass('js-pause');
    }
  }

  const init = () => {
    if (!$('.research-vidfeat').length) {
      return;
    }

    $('.research-vidfeat .video__play').on('click keypress', vidInline);
  };

  return {
    init,
  };
})();

export default researchVidFeat;